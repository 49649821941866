// Main React imports
import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';

// RTK Store
import store from './Redux/store';

// Chakra UI
import { ChakraProvider, theme, ColorModeScript } from '@chakra-ui/react';

import App from './App';
import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import * as amplitude from '@amplitude/analytics-browser';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
const splitConfig = {
  core: {
    authorizationKey: 'n4rpe6kcmqk4cicttoed9c917932mg3n5ve0',
    key: 'hello',
  },
};

const amplitudeProductionApiKey = "8b826e9543921125d139ec0e19d9c13b";
const amplitudeStagingApiKey = "833ac2672b5f04057ffaa2b71872f395";

if (process.env.NODE_ENV === "production") {
  amplitude.init(amplitudeProductionApiKey);
} else {
  amplitude.init(amplitudeStagingApiKey);
}

root.render(
  <StrictMode>
    <Provider store={store}>
      <SplitFactoryProvider config={splitConfig}>
        <ChakraProvider theme={theme}>
          <ColorModeScript />
          <App />
        </ChakraProvider>
      </SplitFactoryProvider>
    </Provider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
